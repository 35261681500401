import app from "firebase/app"
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/analytics'
// import config from '../firebaseConfig'
import config from '../config'



class Firebase{

  constructor(){

    if (typeof window !== 'undefined') {
    app.initializeApp(config)


    this.db = app.firestore()
    this.auth = app.auth()
    this.analytics = app.analytics()
    this.storage = app.storage()

    this.uiConfig = {
      // Popup signin flow rather than redirect flow.
      signInFlow: 'popup',
      // We will display Google and Facebook as auth providers.
      signInOptions: [
        app.auth.EmailAuthProvider.PROVIDER_ID,
        app.auth.GoogleAuthProvider.PROVIDER_ID        
      ],
      callbacks: {
        // Avoid redirects after sign-in.
        signInSuccessWithAuthResult: () => false
      }
    }

    }
  }

  async register(firstname, lastname, email, password, isCompleted) {
    try{
      const newUser = await this.auth.createUserWithEmailAndPassword(
        email,
        password
      )
      await newUser.user.updateProfile({
        displayName: firstname
      })
      const dbUser = {
        uid: newUser.user.uid,
        firstname: firstname,
        lastname: lastname,
        email: newUser.user.email,
        isDoctor: false,
        isCompleted
      }

      this.db.collection('user').doc(dbUser.uid).set(dbUser)

      const verify = await newUser.user.sendEmailVerification()
      .then(() => {
        return true
      });

      return {created: true, verify}
    }catch(err){
      return {error: {...err}}
    }
  } 

  async registerDoc(firstname, lastname, email, password, isDoctor, isActive, isCompleted) {
    try{
      const newUser = await this.auth.createUserWithEmailAndPassword(
        email,
        password
      )
      await newUser.user.updateProfile({
        displayName: firstname
      })
      const dbUser = {
        uid: newUser.user.uid,
        firstname: firstname,
        lastname: lastname,
        email: newUser.user.email,
        isDoctor: isDoctor,
        isActive,
        isCompleted
      }

      this.db.collection('doctor').doc(dbUser.uid).set(dbUser)

      const verify = await newUser.user.sendEmailVerification()
      .then(() => {
        return true
      });

      return {created: true, verify}
    }catch(err){
      return {error: {...err}}
    }
  } 

  async registerMedPro(firstname, lastname, email, password, isMedPro, isActive, isCompleted) {
    try{
      const newUser = await this.auth.createUserWithEmailAndPassword(
        email,
        password
      )
      await newUser.user.updateProfile({
        displayName: firstname
      })
      const dbUser = {
        uid: newUser.user.uid,
        firstname: firstname,
        lastname: lastname,
        email: newUser.user.email,
        isMedPro,
        isActive,
        isCompleted
      }

      this.db.collection('doctor').doc(dbUser.uid).set(dbUser)

      const verify = await newUser.user.sendEmailVerification()
      .then(() => {
        return true
      });

      return {created: true, verify}
    }catch(err){
      return {error: {...err}}
    }
  } 

  async login(email, password){
    try{
      const user = await this.auth.signInWithEmailAndPassword(email, password)

      if(user.user.emailVerified){
    
        let userRef = this.db.collection('user').doc(user.user.uid)
        

        let dbUser = await userRef.get().then(function (doc) {
          if(doc.exists){
            return {user: {...doc.data()}}
          }else{
            return {error: {message: "Patient introuvable. Si vous etes medecin, veuillez vous connecter ", check: true}}
          }
        }) 
        return dbUser 
      }else{
        const verify = await user.user.sendEmailVerification()
        .then(() => {
          return true
        });

        return {created: true, verify}
      }
    }catch(err){
      return {error: {...err}}
    }
  }

  async loginDoc(email, password){
    try{
      const user = await this.auth.signInWithEmailAndPassword(email, password)
    
      let userRef = this.db.collection('doctor').doc(user.user.uid)
      
      let dbUser = await userRef.get().then(function (doc) {
        if(doc.exists){
          return {user: {...doc.data()}}
        }
      })
      return dbUser
    }catch(err){
      return {error: {...err}}
    }
  }

  async resetPassword(email){
    await this.auth.sendPasswordResetEmail(email)
  }


  async logout(){
    await this.auth.signOut()
  }
}

export default Firebase