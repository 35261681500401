// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-doctor-index-js": () => import("./../../../src/pages/doctor/index.js" /* webpackChunkName: "component---src-pages-doctor-index-js" */),
  "component---src-pages-doctor-signup-js": () => import("./../../../src/pages/doctor/signup.js" /* webpackChunkName: "component---src-pages-doctor-signup-js" */),
  "component---src-pages-doctor-signupmedpro-js": () => import("./../../../src/pages/doctor/signupmedpro.js" /* webpackChunkName: "component---src-pages-doctor-signupmedpro-js" */),
  "component---src-pages-doctors-js": () => import("./../../../src/pages/doctors.js" /* webpackChunkName: "component---src-pages-doctors-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-medpros-js": () => import("./../../../src/pages/medpros.js" /* webpackChunkName: "component---src-pages-medpros-js" */),
  "component---src-pages-resetpassword-js": () => import("./../../../src/pages/resetpassword.js" /* webpackChunkName: "component---src-pages-resetpassword-js" */),
  "component---src-pages-signin-js": () => import("./../../../src/pages/signin.js" /* webpackChunkName: "component---src-pages-signin-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-verified-js": () => import("./../../../src/pages/verified.js" /* webpackChunkName: "component---src-pages-verified-js" */),
  "component---src-pages-verify-js": () => import("./../../../src/pages/verify.js" /* webpackChunkName: "component---src-pages-verify-js" */)
}

